.landing-sub-page-layout {
  max-width: 1536px;
  min-width: 1200px;
  width: calc(100% - 96px);
  height: 800px;
  position: relative;

  display: flex;
  align-items: center;
}

.default {
  transform: translate3d(0px, 100px, 0px);
  opacity: 0;
  transform-style: preserve-3d;
  transition: all 500ms linear 0s;

  &.started {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}
