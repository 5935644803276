.landing-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-block-start: 24px;
  margin-inline: 48px;

  .footer-content {
    width: 100%;
    min-width: 1200px;
    max-width: 1536px;
  }

  .logos {
    justify-content: space-between;
    padding-block: 28px 52px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }

  .media-links {
    gap: 16px;
    display: flex;
    margin-top: 1px;
  }

  .website-links {
    padding-block: 24px 96px;
    display: flex;
    justify-content: space-between;

    .website-links-header {
      color: var(--Black, #000);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px; /* 125% */
    }
    a {
      color: #000;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }

  .service-links {
    align-self: flex-start;
  }

  &.mobile {
    min-width: 100%;
    margin: auto;
    .footer-content {
      padding-inline: 2.19rem;

      width: 100%;
      max-width: auto;
      min-width: auto;
    }

    .divider {
      margin: 0;
      border-color: var(--black-3, rgba(0, 0, 0, 0.03));
    }
    .logo {
      transform: scale(0.857);
    }
    .logos {
      flex-direction: column;
      padding-block: 0 1.5rem;
    }
    .media-links {
      gap: 1.5rem;
    }

    .website-links {
      padding-block: 1.5rem 06rem;

      .website-links-header {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.25rem; /* 125% */
      }
      a {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem; /* 133.333% */
      }
    }
  }
}
