.sign-in-modal {
  .sign-in-content {
    :global {
      .ant-message {
        margin-top: -12px;
        position: absolute;
      }
    }
  }

  .forgot-password-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    &-main-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-top: 12px;
    }

    &-desc {
      width: 320px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 4px;
      margin-bottom: 24px;
    }

    &-desc2 {
      width: 335px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 4px;
      margin-bottom: 24px;
    }

    &-form {
      .submit-button {
        width: 100%;
        height: 48px;
        border-radius: 8px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.6) !important;
          span {
            color: #ffffff !important;
          }
        }
        span {
          font-size: 16px;
          font-weight: 700;
        }
      }
      .email-input {
        text-align: center;
      }
    }
  }

  .reset-password-content {
    text-align: center;

    &-title {
      font-size: 24px;
      font-weight: 400;
    }
  }

  .account-not-found-content {
    text-align: center;

    &-title {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 38px;
    }

    &-desc {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 61px;
    }
  }
  .sign-up-button {
    margin-top: 12px;
    border-radius: 8px;
    border: 1px solid var(--Grey, #f2f4f5);
    background: #fff;
    border-radius: 8px;
    color: var(--black, #000);
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    line-height: 24px;
    box-shadow: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6) !important;
      span {
        color: #ffffff !important;
      }
    }
    strong {
      font-size: 16px;
      font-weight: 700;
    }
  }

  :global {
    .ant-modal-content {
      padding: 48px 32px 32px;
      background-color: #fff !important;

      .ant-result {
        padding: 50px;

        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-title {
          font-weight: 700;
          font-size: 24px;
          color: #000000;
          line-height: 32px;
        }
        &-subtitle {
          font-weight: 400;
          font-size: 13px;
          color: #000000;
          line-height: 16px;
        }
      }

      .ant-modal-close-icon {
        width: 24px;
        height: 24px;
        background: #000000;
        color: #ffffff;
        border-radius: 50%;
        justify-content: center;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 12px;

      .ant-checkbox-wrapper {
        span {
          font-size: 10px;
        }
      }

      .ant-checkbox-inner {
        background-color: #ffffff;
        border: 1px solid #d9d9d9;
      }

      .ant-input {
        border: none;
        height: 48px;
        line-height: 48px;
        background: rgba(242, 244, 245, 0.6);
        box-shadow: none;
        border: none;
      }

      .ant-input:focus {
        box-shadow: none !important;
        border: none;
      }

      .ant-input:hover {
        box-shadow: none;
        border: none;
        border-color: #fff;
        background: var(--Grey, #f2f4f5);
      }

      .ant-input-password {
        border: none;
        height: 44px;
        line-height: 44px;
        background: rgba(242, 244, 245, 0.6);
        box-shadow: none;
        border: none;
      }

      .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
        border: none;
      }

      .ant-input-password:hover {
        box-shadow: none;
        border: none;
        background: rgba(242, 244, 245, 0.6);
      }

      .ant-btn {
        border: none;
      }
    }
  }
}
