@prefixCls: custom-collapse;

.@{prefixCls}-container {
  width: 720px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: transparent;

  :global {
    .ant-collapse-item {
      padding: 32px;
      border-radius: 24px;
      border-bottom: 0;
      background-color: #fff;
      border-bottom-color: rgba(242, 244, 245, 1);

      .ant-collapse-header {
        padding: 0;

        .ant-collapse-header-text {
          .ant-typography {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }

        > .ant-collapse-expand-icon {
          width: 24px;
          height: 24px;

          border: 2px solid #000;
          border-radius: 50%;

          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;

          padding-inline-start: 0;
        }
      }

      > .ant-collapse-content {
        > .ant-collapse-content-box {
          padding: 8px 56px 0 0;

          color: var(--Black, #000);
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}
