.signContent {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .title {
    color: #000000;
    text-align: center;
    font-size: 24px;
    font-weight: 300;

    strong {
      font-size: 24px;
      font-weight: 700;
    }

    margin-bottom: 32px;
  }

  .sign-in-form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;

    .or {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      color: #0000004d;
      margin-bottom: 0;
    }

    :global {
      .ant-form-item-control-input-content {
        .ant-btn {
          border-radius: 8px;
        }

        .ant-input {
          border-radius: 8px;
        }
      }
    }
  }
}

@media screen and(max-width: 768px) {
  .signContent {
    width: 100%;
  }
}
