.joinform {
  width: 100%;

  .forgot-password {
    text-align: center;
    width: 100%;
    height: auto;
    color: #000000;
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 12px 0px;
    &:hover {
      span {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }

  .sign-in-button {
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 48px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6) !important;
      span {
        color: #ffffff !important;
      }
    }
  }

  .sign-up-button {
    margin-top: 12px;
    border-radius: 8px;
    border: 1px solid var(--Grey, #f2f4f5);
    background: #fff;
    border-radius: 8px;
    color: var(--black, #000);
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    line-height: 24px;
    box-shadow: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.6) !important;
      span {
        color: #ffffff !important;
      }
    }
    strong {
      font-size: 16px;
      font-weight: 700;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 12px !important;
      height: 48px;
      .ant-checkbox-wrapper {
        span {
          font-size: 12px;
        }
      }
      .ant-input-password {
        height: 48px !important;
        line-height: 48px !important;
        input {
          background: rgba(242, 244, 245, 0.2);
        }
      }
      .ant-input {
        border: none;
      }
      .ant-form-item-control-input {
        height: 48px;
      }
      .ant-input-affix-wrapper {
        background-color: #f0f2f2;
        padding: 0px 11px;
      }

      .ant-btn,
      .ant-btn-primary {
        border: none;
        box-shadow: none;
      }
    }
  }
}
@media screen and(max-width: 768px) {
  .joinform {
    width: 342px;
  }
}
