@prefixCls: enterprise-invite-popup;

.@{prefixCls}-modal-root {
  overflow: hidden;
}

.@{prefixCls}-modal {
  :global {
    div.ant-modal-content {
      border-radius: 0px 24px 24px 24px;
      background: #fff;
      padding: 0 24px 24px 24px;

      .ant-modal-header {
        height: 64px;
        display: flex;
        align-items: center;
        background: #fff;
        margin-bottom: 24px;
        border-bottom: 1px solid var(--Grey, #f2f4f5);

        .ant-modal-title {
          width: 100%;
        }
      }
    }
  }

  .@{prefixCls}-modal-title {
    width: 100%;
  }

  .@{prefixCls}-modal-content {
    .@{prefixCls}-form {
      display: flex;
      flex-flow: column;
      gap: 24px;

      :global {
        .ant-form-item {
          margin-bottom: 0;
          border-radius: 8px;

          .ant-input {
            background: var(--grey-60, rgba(242, 244, 245, 0.6));
            height: 40px;
          }

          .ant-form-item-label {
            padding-bottom: 4px;

            label {
              color: var(--Black, #000);
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }
      }
    }

    .@{prefixCls}-description {
      color: var(--Black, #000);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    button.@{prefixCls}-schedule-call-button {
      display: flex;

      padding: 10px 0px;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      border: none;

      color: var(--Black, #000);
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px; /* 142.857% */

      height: 40px;
      border-radius: 8px;
      background: var(--Green, #00f0a0);

      &:hover {
        background: var(--Green, #00f0a0cc);
      }
    }
  }
}

button.@{prefixCls}-button {
  color: var(--Black, #000);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px; /* 120% */
  width: 100%;

  border-radius: 8px;
  background: var(--Green, #00f0a0);

  border: none;

  &:hover {
    background: var(--Green, #00f0a0cc);
  }
}
