.header-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 1000;

  &.fixed-header {
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  }
}
.header-main {
  width: 100%;
  max-width: 1536px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  margin-inline: 48px;

  .download-button {
    height: 32px;
    padding: 0px 8px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: 16px;
    border: 1px solid var(--mainFill, #000);
    background: var(--surface, #fff);

    color: var(--mainFill, #000);
    font-size: 14px;
    font-weight: 600;
    line-height: 24px; /* 171.429% */

    :global {
      .ant-btn-icon {
        margin-inline-end: 4px;
      }
    }

    &:hover:not(:disabled):not(.ant-btn-disabled) {
      color: var(--mainFill, #000);
    }
  }
  .text {
    cursor: pointer;
    color: var(--Black, #000);
    font-size: 14px;

    font-weight: 700;
    line-height: 20px; /* 125% */
  }

  .profile {
    display: flex;
    gap: 32px;
    align-items: center;

    button.enterprise-invite-button {
      color: #000;

      display: inline-flex;
      padding: 4px 12px 4px 8px;
      justify-content: center;
      align-items: center;

      color: var(--Black, #000);
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
      text-transform: uppercase;

      border-radius: 16px;
      background: var(--Green, #00f0a0);

      border: none;

      &:hover {
        background-color: #00f0a0cc;
        color: var(--Black, #000);
      }

      :global {
        .ant-btn-icon {
          margin-inline-end: 4px;
        }
      }
    }
  }

  .navigator {
    padding: 6px 0;
    color: #000;
    cursor: pointer;

    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .continue-button {
    color: #fff;
    border-radius: 16px;
  }

  .sign-up-button {
    width: 112px;
    height: 32px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: 16px;

    color: #fff;
    background-color: #000;

    font-size: 14px;
    font-weight: 700;
    line-height: 24px;

    box-shadow: none;
    border: none;

    &:hover {
      background-color: #00f0a0;
      color: #000;
    }
  }

  .mobile-sign-up-button {
    display: inline-flex;
    padding: 7px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    color: #fff;
    background-color: #000;

    color: #fff;
    font-size: 18px;
    font-weight: 700;

    box-shadow: none;
    border: none;
    height: 36px;
    width: 123px;
    &:hover {
      background-color: #00f0a0;
      span {
        color: #000;
      }
    }
  }
}

div.dropdown-overlay {
  :global {
    .ant-dropdown-menu {
      background-color: #fff;
      min-width: 200px;
      box-shadow: 0px 32px 80px 0px rgba(0, 10, 20, 0.2);

      .ant-dropdown-menu-item {
        padding: 8px 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px; /* 123.077% */

        &:hover {
          background: var(--green-20, rgba(0, 240, 160, 0.2));
        }
      }
    }
  }
}
